/*--------------- CONTAIN_START --------------*/
.banner-block-hp {
  position: relative;
  overflow: hidden; }

.banner-block-overlay-hp {
  background: rgba(0, 0, 0, 0.5);
  min-height: calc(100vh - 100px); }

.banner-block-in-hp {
  padding-bottom: 370px; }

.banner-img-hp {
  position: absolute;
  left: 0;
  top: 200px;
  width: 319px;
  z-index: 1; }

.banner-img-hp img {
  width: 100%; }

.banner-img2-hp {
  position: absolute;
  right: -34px;
  top: -34px;
  width: 201px; }

.banner-img2-hp img {
  width: 100%; }

.banner-img3-hp {
  position: absolute;
  bottom: 0;
  width: 100%; }

.banner-img3-hp img {
  width: 100%; }

.banner-text-hp {
  text-align: center;
  padding: 116px 0 0 0; }

.banner-text-hp img {
  max-width: 100%; }

.banner-subtext-hp {
  text-align: center;
  padding: 50px 0 50px 0;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  font-weight: 700; }

.nittetsu-block-hp {
  margin-top: -160px;
  overflow: hidden; }

.nittetsu-block-in-hp {
  text-align: center;
  position: relative; }

.nittetsu-block-in-hp:before {
  content: '';
  position: absolute;
  left: -155px;
  top: 65px;
  background: url(../../images/top/nittetsu_img_1.png) no-repeat center center;
  background-size: 100%;
  width: 357px;
  height: 676px;
  z-index: 2; }

.nittetsu-block-in-hp:after {
  content: '';
  position: absolute;
  right: -441px;
  top: 105px;
  background: url(../../images/top/nittetsu_img_2.png) no-repeat center center;
  background-size: 100%;
  width: 900px;
  height: 649px; }

.nittetsu-middle-hp {
  padding: 55px 0 115px 0;
  position: relative; }

.nittetsu-desc-hp {
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  font-weight: 700;
  max-width: 605px;
  width: 100%;
  margin: auto;
  padding: 30px 0; }

.nittetsu-banner-hp {
  position: relative; }

.nittetsu-banner-hp:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../images/top/nittetsu_back_blue.svg) no-repeat top center;
  z-index: 1;
  background-size: 100%; }

.nittetsu-banner-hp img {
  width: 100%; }

.service-block-hp {
  padding: 50px 0 0 0; }

.service-middle-hp {
  margin: 30px 0 100px 0;
  overflow: hidden; }

.service-box-hp {
  height: 510px;
  display: flex;
  align-items: center;
  position: relative;
  background: url(../../images/top/service_back2.png) no-repeat right top;
  background-size: auto 100%; }

.service-box-hp:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1000%;
  right: -1000%;
  background: rgba(0, 116, 253, 0.6); }

.service-box-in-hp {
  width: 50%;
  padding: 0 15px;
  margin-right: 0;
  margin-left: auto; }

.service-title-hp {
  font-size: 30px;
  line-height: 56px;
  color: #fff;
  font-weight: 700;
  border-bottom: 4px solid #fff;
  display: inline;
  padding-bottom: 5px; }

.service-desc-hp {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
  padding: 25px 0 0 0; }

.service-btn-hp {
  padding: 50px 0 0 0; }

.advantage-block-hp {
  background: #FFD50E;
  padding: 70px 0 0 0; }

.advantage-img-hp {
  text-align: center;
  margin-top: -180px; }

.advantage-img-hp img {
  max-width: 100%;
  margin: auto;
  display: flex; }

.advantage-btn-hp {
  text-align: center;
  margin-top: -110px;
  padding-bottom: 60px; }

.production-block-hp {
  background: #CCE3FF;
  padding: 50px 0 0 0; }

.production-middle-hp {
  margin: 65px 0 0 0; }

.production-box-hp {
  background: url(../../images/top/production_back2.png) no-repeat left top;
  background-size: auto 100%; }

.production-box-hp:after {
  left: -1000%;
  right: auto; }

.production-box-hp .service-box-in-hp {
  padding: 0;
  margin-right: auto;
  margin-left: 0;
  max-width: 430px; }

.production-box-hp .service-desc-hp {
  padding: 45px 0 0 0; }

.production-box-hp .service-btn-hp {
  padding: 80px 0 0 0; }

.company-block-hp {
  padding: 65px 0 0 0;
  position: relative; }

.company-block-hp:after {
  content: '';
  position: absolute;
  left: -90px;
  top: -140px;
  background: url(../../images/top/company_shape_1.png) no-repeat center center;
  background-size: 100%;
  width: 262px;
  height: 316px; }

.company-block-hp:before {
  content: '';
  position: absolute;
  right: -90px;
  top: 0;
  background: url(../../images/top/company_shape_2.png) no-repeat center center;
  background-size: 100%;
  width: 312px;
  height: 290px; }

.company-block-in-hp {
  position: relative; }

.company-box-hp .service-title-hp {
  color: #000;
  border-color: #000; }

.company-box-hp .service-desc-hp {
  color: #000; }

.company-img-hp {
  margin-top: -260px; }

.company-img-hp img {
  width: 100%; }

/*--------------- CONTAIN_END --------------*/
